import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface ModalData {
  title: string;
  closeButtonLabel?: string;
  saveButtonLabel?: string;
  content: string;
  url?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ErrorModalService {
  private _showModal = new Subject<ModalData | undefined>();
  showModal$ = this._showModal.asObservable();

  openModal(data?: ModalData): void {
    this._showModal.next(data);
  }

  closeModal(): void {
    this._showModal.next(undefined);
  }
}
