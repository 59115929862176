import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SpinnerService extends NgxSpinnerService {
  private spinnerText$ = new BehaviorSubject<string>('');

  public getSpinnerObservable(): Observable<string> {
    return this.spinnerText$.asObservable();
  }

  override show(name?: string | undefined): Promise<unknown> {
    this.spinnerText$.next(name ?? 'Default');
    return super.show();
  }

  override hide(): Promise<unknown> {
    this.spinnerText$.next('');
    return super.hide();
  }
}
