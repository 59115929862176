import { environment } from '../enviroments/enviroment';
import { ICrypto } from '../interfaces/encryption-service.interface';
import { AES, enc, mode, pad } from 'crypto-js';

export class CryptoService implements ICrypto {
  encrypt(item: string): Promise<string> {
    const key = enc.Utf8.parse(environment.aes.key);
    const iv = enc.Utf8.parse(environment.aes.iv);
    const encrypted = AES.encrypt(enc.Utf8.parse(item), key, {
      keySize: 128 / 8,
      iv: iv,
      mode: mode.CBC,
      padding: pad.Pkcs7,
    });

    return Promise.resolve(encrypted.toString());
  }

  decrypt(item: string): Promise<string> {
    const key = enc.Utf8.parse(environment.aes.key);
    const iv = enc.Utf8.parse(environment.aes.iv);
    const decrypted = AES.decrypt(item, key, {
      keySize: 128 / 8,
      iv,
      mode: mode.CBC,
      padding: pad.Pkcs7,
    });

    return Promise.resolve(decrypted.toString(enc.Utf8));
  }
}
