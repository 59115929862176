import { Injectable } from '@angular/core';
import { PostMessageInterface } from '../interfaces/post-message-interface';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  send<T>(message: PostMessageInterface<T>): void {
    window.parent.postMessage(message, { targetOrigin: '*' });
  }
}
