<app-crediflow-layout>
  <p [ngClass]="{isFirstScreen: 'first-screen'}" class="giveaway" *ngIf="isGiveawayActive$ | async"> SORTEO ACTIVO </p>
  <router-outlet></router-outlet> 
  
  <app-error-modal></app-error-modal>
  <img #loadingImage class="loading-icon" src="/assets/image/icons/loading-ripple.svg" alt="loader">
  <ngx-spinner
    *ngIf="imageTemplate"
    bdColor="rgb(132 46 169 / 0.8)"
    size="medium"
    color="white"
    [fullScreen]="true"
    [template]="imageTemplate"
  >
    <p class="spinner-text">{{ spinnerText$ | async }}</p>
  </ngx-spinner>
</app-crediflow-layout>

