import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class QueryService {
  public getParamsFromRoute<T>(route: ActivatedRoute): Observable<Partial<T>> {
    return route.queryParams.pipe(
      map((params) => {
        const result: Partial<T> = {};
        for (const key in params) {
          if (params[key] !== undefined) {
            const value = params[key];
            const typedKey = key as keyof T;
            result[typedKey] = !isNaN(parseFloat(value)) ? parseFloat(value) : value;
          }
        }
        return result;
      }),
    );
  }
}
