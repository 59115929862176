<!-- error-modal.component.html -->
<div class="modal-background" *ngIf="isModalOpen">
  <div class="modal-content">
    <button type="button" class="close-btn" (click)="closeModal()">
      ✕
    </button>
    <h5 class="modal-title">{{ modalTitle }}</h5>
    <div class="modal-body">
      {{ content }}
    </div>
    <div class="modal-footer">
      <ng-container *ngIf="closeButtonLabel && closeButtonLabel !== ''">
        <button type="button" class="btn btn-secondary" (click)="closeModal()">
          {{ closeButtonLabel }}
        </button>
      </ng-container>

      <ng-container *ngIf="saveButtonLabel && saveButtonLabel !== ''">
        <button type="button" class="btn btn-primary" (click)="goTo()">
          {{ saveButtonLabel }}
        </button>
      </ng-container>

      <!-- <ng-template #noUrl>
        <button type="button" class="btn btn-primary" (click)="saveChanges()">
          {{ saveButtonLabel }}
        </button>
      </ng-template> -->
    </div>
  </div>
</div>
