import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { LoggerService } from './app/services/logger.service';

import * as Sentry from "@sentry/angular-ivy";

import { AppModule } from './app/app.module';

Sentry.init({
  dsn: "https://2718ff365cfd6577f8a7a97bb37593c9@o4507113253371904.ingest.de.sentry.io/4507197603184720",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => new LoggerService().error(err));
