import { Component } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-credit-info-summary',
  templateUrl: './credit-info-summary.component.html',
  styleUrls: ['./credit-info-summary.component.scss'],
})
export class CreditInfoSummaryComponent {
  public data$ = this.dataService.getCreditDataObservable();
  public isGiveawayActive$ = this.dataService.getIsGiveawayActive$();
  public showData = false;
  constructor(public dataService: DataService) {}

  toggleData(): void {
    this.showData = !this.showData;
  }
}
