import { IParser } from '../interfaces/parse-service.interface';

export class ParseService implements IParser {
  stringify<T>(item: T): string {
    return JSON.stringify(item);
  }

  parse<T>(item: string): T {
    return JSON.parse(item) as T;
  }

  parseInstance<T>(item: string, itemConstructor: new (item: Partial<T>) => T): T {
    return new itemConstructor(JSON.parse(item));
  }
}
