<button
  [class]="size"
  [ngClass]="{disabled, loading}"
  [disabled]="disabled"
  [type]="type"
  type="submit"
  (click)="handleClick()"
>
  <ng-content *ngIf="!loading; else loadingTemplate" />
</button>

<ng-template #loadingTemplate>
  <img src="/assets/image/icons/loading-2.gif" alt="loading...">
</ng-template>
