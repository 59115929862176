import { Injectable } from '@angular/core';
import { IsUrlUsedResponse } from '../models/response/is-url-used.response';
import { AgilcredBaseService } from './agilcred-base.service';
import { Observable } from 'rxjs';
import { CheckUrlDniResponse } from '../models/response/check-url-dni.response';

@Injectable({
  providedIn: 'root'
})
export class GiveawayService extends AgilcredBaseService {

  public checkIfUrlIsUsed(link: string): Observable<IsUrlUsedResponse> {
    return this.post('giveaway/is-url-used', { link });
  }

  public checkUrlDni(data: {link: string; dni: string}): Observable<CheckUrlDniResponse> {
    return this.post('giveaway/check-url-dni', data);
  }

}
