/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../enviroments/enviroment';

@Injectable({
  providedIn: 'root',
})
export abstract class AgilcredBaseService {
  protected apiUrl = environment.apiUrl;
  protected http = inject(HttpClient);

  get<T>(endpoint: string, params?: HttpParams): Observable<any> {
    return this.http.get<T>(`${this.apiUrl}/${endpoint}`, { params });
  }

  post<T>(endpoint: string, data: any, params?: HttpParams): Observable<T> {
    return this.http.post<T>(`${this.apiUrl}/${endpoint}`, data, { params });
  }

  put<T>(endpoint: string, data: any): Observable<T> {
    return this.http.put<T>(`${this.apiUrl}/${endpoint}`, data);
  }

  delete<T>(endpoint: string): Observable<T> {
    return this.http.delete<T>(`${this.apiUrl}/${endpoint}`);
  }
}
