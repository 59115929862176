import { Component } from '@angular/core';

@Component({
  selector: 'app-crediflow-layout',
  templateUrl: './crediflow-layout.component.html',
  styleUrls: ['./crediflow-layout.component.scss'],
})
export class CrediflowLayoutComponent {

  showTyC = false;
  showLinks = false;

  closeTyC():void {
    this.showTyC = false;
  }

  openTyC():void {
    this.showTyC = true;
  }

  closeFooter():void {
    this.showLinks = false;
  }

  openFooter():void {
    this.showLinks = true;
  }


}
