export enum CreditErrorType {
  BANK,
  GENERIC,
  REQUEST,
  RISK,
  SCORE,
  IDENTITY,
  SMS,
  NO_NETWORK,
  CREATE,
  HAVE_LOAN
}

export type CreditError = {
  title?: string,
  messages: string[];
  whatsapp?: string;
};

const whatsapp = 'https://wa.me/34670909086?text=%C2%A1Hola!%20Quiero%20hablar%20con%20un%20asesor';

export const creditErrors = new Map<CreditErrorType | undefined, CreditError>([
  [
    CreditErrorType.GENERIC,
    {
      messages: ['Ocurrió un error inesperado...'],
      whatsapp,
    },
  ],
  [
    CreditErrorType.BANK,
    {
      messages: ['Ocurrió un error con verificación de cuenta bancaria...'],
      whatsapp,
    },
  ],
  [
    CreditErrorType.CREATE,
    {
      messages: ['Ocurrió un error inesperado...'],
      whatsapp,
    },
  ],
  [
    CreditErrorType.REQUEST,
    {
      messages: ['No pudimos procesar su solicitud. Para más información comuniquese con atención al cliente.'],
      whatsapp,
    },
  ],
  [
    CreditErrorType.HAVE_LOAN,
    {
      title: 'Ya posee un préstamo activo.',
      messages: ['No podemos procesar su solicitud. Ya posee un préstamo activo.'],
      whatsapp,
    },
  ],
  [
    CreditErrorType.RISK,
    {
      title: 'No podemos concederte el préstamo solicitado.',
      messages: ['Nuestro análisis de fraude, capacidad de pago y solvencia (socre crediticio) ha rechazado la solicitud.'],
      whatsapp,
    },
  ],
  [
    CreditErrorType.SMS,
    {
      messages: ['No podemos procesar tu solicitud...parece tu número telefónico es incorrecto'],
      whatsapp,
    },
  ],
  [
    CreditErrorType.SCORE,
    {
      title: 'No podemos concederte el préstamo solicitado.',
      messages: ['Nuestro análisis de capacidad de pago y solvencia (socre crediticio) ha rechazado la solicitud.',' ',' ','¿De qué se trata?','De forma muy resumida, se trata de una herramienta que utilizan las entidades financieras para decidir si concede el préstamo en función de tu capacidad de endeudamiento y de tu solvencia. Se analiza la deuda, ingresos y egresos del cliente para determinar si puede realizar el pago.'],
      whatsapp,
    },
  ],
  [
    CreditErrorType.IDENTITY,
    {
      messages: ['No podemos procesar tu solicitud... no pudimos validar tu identidad'],
      whatsapp,
    },
  ],
  [
    CreditErrorType.NO_NETWORK,
    {
      messages: [
        'No podemos procesar tu solicitud, hubo un error con tu conexión',
        'Conéctate a internet de nuevo y vuelve a intentarlo',
      ],
      whatsapp,
    },
  ],
  [
    undefined,
    {
      messages: ['Error no especificado'],
      whatsapp,
    },
  ],
]);
