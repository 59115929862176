import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreditRequestLayoutComponent } from './layouts/credit-request-layout/credit-request-layout.component';
import { AppModules } from './models/enums/routes.enum';

const routes: Routes = [
  {
    path: AppModules.CREDIT_REQUEST,
    component: CreditRequestLayoutComponent,
    loadChildren: () => import('./modules/credit-request/credit-request.module').then((m) => m.CreditRequestModule),
  },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '**', redirectTo: 'home', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { bindToComponentInputs: true , scrollPositionRestoration: 'enabled',  anchorScrolling: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
