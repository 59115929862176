import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreditSimulatorComponent } from 'src/app/modules/simulator/pages/credit-simulator/credit-simulator.component';
import { SliderAgilCredComponent } from './pages/credit-simulator/slider-agil-cred/slider-agil-cred.component';
import { InfoCreditSimulatorComponent } from './pages/credit-simulator/info-credit-simulator/info-credit-simulator.component';
import { RouterModule } from '@angular/router';
import { ButtonComponent } from 'src/app/shared/reusable/button/button.component';
import { NgxSliderModule } from 'ngx-slider-v2';
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
  imports: [
    ButtonComponent,
    NgxSliderModule,
    NgxSpinnerModule,
    CommonModule,
    RouterModule.forChild([
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home',
      },
      { path: 'home', component: CreditSimulatorComponent },
    ]),
  ],
  declarations: [CreditSimulatorComponent, SliderAgilCredComponent, InfoCreditSimulatorComponent],
})
export class SimulatorModule {}
