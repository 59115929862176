<div class="info-container" *ngIf="userRequestCreation$ | async as userRequestCreation">
  <div class="card__content" [class]="whiteColor">
    <div>Intereses</div>
    <div [class]="whiteBold">
      <span>{{
        interest
        ? interest
        : userRequestCreation.interes
      }}</span>
      <span>€ </span>
    </div>
  </div>
  
  <div class="card__content" [class]="whiteColor">
    <div class="card__content__bold">Total a devolver</div>
    <div [class]="whiteBold">
      <span style="font-size: 20px; font-weight: 600;">
        {{
          totalToReturn
          ? totalToReturn
          : userRequestCreation.importe_total
        }}</span>
        <span style="font-weight: 600;">€ </span>
    </div>
  </div>
  
  <div class="card__content" [class]="whiteColor">
    <div class="card__content__bold" [class]="whiteBold">Fecha de devolución</div>
    <div class="card__content__bold" [class]="whiteBold">
      {{
        returnDate
          ? (returnDate | date : 'dd/MM/yyyy')
          : (userRequestCreation.fecha_devolucion | date : 'dd/MM/yyyy')
      }}
    </div>
  </div>
</div>
