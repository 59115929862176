/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { SpinnerService } from './services/spinner.service';
import { ICrypto, ICryptoToken } from './interfaces/encryption-service.interface';
import { DataService } from './services/data.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable, combineLatest, filter, map } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public imageTemplate = '';
  public spinnerText$ = this.spinner.getSpinnerObservable();
  public encryptedToken = '';
  public isGiveawayActive$ = this.isGiveawayActiveSimulatorScreen$();

  get isFirstScreen$(): Observable<boolean> {
    return this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => {
        const url = this.router.url;
        const segments = url.split('/');
        return segments[segments.length - 1];
      }),
      map((url) => url.includes('home')),
    );
  }

  constructor(
    private spinner: SpinnerService,
    @Inject(ICryptoToken) private crypto: ICrypto,
    private dataService: DataService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  @ViewChild('loadingImage', { static: true }) set loadingImage(image: ElementRef<HTMLImageElement>) {
    this.imageTemplate = image.nativeElement.outerHTML;
    image.nativeElement.style.display = 'none';
  }

  ngOnInit(): void {
    this.clearBwoser();

    // this.generateEncryptedToken();
  }

  clearBwoser(): void {
    sessionStorage.clear();
    localStorage.clear();
  }

  private isGiveawayActiveSimulatorScreen$(): Observable<boolean> {
    return combineLatest([this.dataService.getIsGiveawayActive$(), this.isFirstScreen$]).pipe(
      map(([active, firstScreen]) => active && firstScreen),
    );
  }

  // generateEncryptedToken(): void {
  //   const data: AuthenticatedUserData = {
  //     token:
  //       'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJhZ2lsY3JlZC1lcy5ob21vLml0c2Zsb3cuaXRzLmNvbS5hciIsImV4cCI6MTcwNjY2MjQwNCwiYXVkIjoiY2E3MjRlNjc4MGZhYzg1YWZmOTJhNzI4ZWQ3YjQ0NmNhMzdhM2M2ZSIsInN1YiI6OTgsInJvbCI6ImNsaWVudGUifQ.-Ew_J1239nXj07Yq2zuJtMgQjzoqOuA209bYVex4WV8',
  //     cellphone: '573206468903',
  //     account_id: '21c43ecc-9da8-40e2-8d33-b9b3a9935c03',
  //     employment_type_id: 3,
  //   }

  //   this.crypto.encrypt(JSON.stringify(data)).then((cyphered) => {
  //     console.log("Original Data:", data);
  //     console.log("Encrypted Data:", cyphered);

  //     const query = encodeURIComponent(btoa(cyphered));
  //     console.log("Encoded Query:", query);

  //     this.encryptedToken = query;

  //     const decodedQuery = atob(decodeURIComponent(query));
  //     console.log("Decoded Query:", decodedQuery);

  //     this.crypto.decrypt(decodedQuery).then((original) => {
  //       console.log("Decrypted Data:", original);
  //     }).catch((error) => {
  //       console.error("Decryption Error:", error);
  //     });
  //   });
  // }
}
