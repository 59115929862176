import { Inject, Injectable } from '@angular/core';
import { IStorage, IStorageToken } from '../interfaces/storage-service.interface';
import { IParser, IParserToken } from '../interfaces/parse-service.interface';
import { ICrypto, ICryptoToken } from '../interfaces/encryption-service.interface';

@Injectable({ providedIn: 'root' })
export class SecureStorageService {
  constructor(
    @Inject(IStorageToken) private storage: IStorage,
    @Inject(IParserToken) private parser: IParser,
    @Inject(ICryptoToken) private crypto: ICrypto,
  ) {}

  async storeEncrypted<T>(key: string, item: T): Promise<void> {
    const parsed = typeof item === 'string' ? item : this.parser.stringify<T>(item);
    this.storage.storeItem(key, await this.crypto.encrypt(parsed));
  }

  async retrieveDecrypted(key: string): Promise<string | null> {
    const ciphered = await this.storage.retrieveItem(key);
    const unciphered = ciphered ? this.crypto.decrypt(ciphered) : null;
    return new Promise((resolve) => resolve(unciphered));
  }

  async retrieveParsed<T>(key: string): Promise<T | null> {
    const retrieved = await this.retrieveDecrypted(key);
    return retrieved ? this.parser.parse(retrieved) : null;
  }
}
