<div class="slider">
  <div class="slider__container">
    <div class="slider__container__title">{{ title }}</div>
    <span class="slider__container__value"
      >{{ isCash ? (value + ' €') : value }} <span class="sub-text-small">{{sub_title}}</span>
    </span>
  </div>
  <div class="custom-slider">
    <ngx-slider
      [options]="options"
      (valueChange)="getValue($event)"
    ></ngx-slider>
  </div>
</div>
