import { Component, Input } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-info-credit-simulator',
  templateUrl: './info-credit-simulator.component.html',
  styleUrls: ['./info-credit-simulator.component.scss'],
})
export class InfoCreditSimulatorComponent {
  @Input() whiteColor = '';
  @Input() whiteBold = '';

  @Input() amountRequested!: number;
  @Input() interest!: number;
  @Input() totalToReturn!: number;
  @Input() returnDate!: string;

  userRequestCreation$ = this.dataService.getCreditDataObservable();

  constructor(private dataService: DataService) {}
}
