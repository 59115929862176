<div class="info-container" *ngIf="data$ | async as info">
  <div class="info-row" (click)="toggleData()">
    <div>
      <p>Importe total</p>
      <div>{{ info.importe_total }} <span>€</span></div>
    </div>
    <div>
      <p>Fecha de devolución</p>
      <div>{{ info.fecha_devolucion | date: 'dd/MM/yyyy' }}</div>
    </div>
    <div>
      <ng-container *ngIf="isGiveawayActive$ | async; else regularLoan">
        <p>Interés TAE(0.00%)</p>
      </ng-container>
      <ng-template #regularLoan>
        <p>Interés TAE({{ info.dias <= 15 ? '21657,30%' : '3124,74%' }})</p>
      </ng-template>
      <div>{{ info.interes }} <span>€</span></div>
    </div>
    <div class="button">
      <img src="/assets/image/icons/chevron.png" [ngClass]="{active: showData}" alt="icon" class="icon">
    </div>
  </div>
  <div class="panel" [ngClass]="{'hidden': !showData}">
    <div>
      <p>Importe solicitado</p>
      <div>{{ info.importe }} <span>€</span></div>
    </div>
    <div>
      <p>Días de devolución</p>
      <div>{{ info.dias }} días</div>
    </div>
  </div>
</div>