import { Injectable } from '@angular/core';
import { Observable, debounceTime, fromEvent, map, startWith } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ResizeService {
  getHeightObservable(el?: HTMLElement): Observable<number> {
    const element = el ?? document.documentElement;
    return fromEvent(window, 'resize').pipe(
      startWith(element.offsetHeight),
      debounceTime(250),
      map(() => element.offsetHeight),
    );
  }
}
